const methodology = [
    {
        id: 1,
        color: "#7f5af0",
        backgroundColor: "rgba(127, 90, 240, .15)",
        title: "01. CONCEPTION",
        content: [
            {
                id: 1,
                icon: "wireframe",
                title: "Wireframing",
                description: "Creation of sketches in order to conceive potential visuals outlining the different options suggested in the specifications, several proposals will be made and will be submitted to the client for approval."
            },
            {
                id: 2,
                icon: "design",
                title: "Designing",
                description: "The low fidelity sketching must be brought to life in order to make the layout better and to provide the client with a wonderful interface that complies with the latest design trends, but also to think about the experience the user will have within the solution."
            },
            {
                id: 3,
                icon: "prototype",
                title: "Prototyping",
                description: "The purpose of this step is to give life to the previously imagined design, it will dictate the behavior and interaction of the user with our components."
            },
            {
                id: 4,
                icon: "database",
                title: "Data Design",
                description: "It's important to think about the database architecture because it will dictate the behavior of the multiple data flows throughout our solution."
            },
            {
                id: 5,
                icon: "architecture",
                title: "Architecture",
                description: "Defining an architecture will allow us to structure our work in order to have a homogeneous set and a maintainable code for the upcoming releases."
            },
        ]
    },
    {
        id: 2,
        color: "#ff8200",
        backgroundColor: "rgba(255, 130, 0, .15)",
        title: "02. INTEGRATION",
        content: [
            {
                id: 1,
                icon: "api",
                title: "API",
                description: "The server part is very important because it allows to catch and redirect important data flows, it will be used as an intermediary between our interfaces and our data center."
            },
            {
                id: 2,
                icon: "interface",
                title: "Interfaces",
                description: "Our interfaces are intended to serve as a facade for our user, they will facilitate a large number of tasks thanks to the study conducted on the human interaction."
            },
            {
                id: 3,
                icon: "mobile",
                title: "Screen Integration",
                description: "Our screens aim to implement the maximum of useful components while thinking about the performance aspect, everything will be optimized to ensure a better experience."
            },
            {
                id: 4,
                icon: "function",
                title: "Functionalities",
                description: "Each feature is researched and tested in order to provide the highest and optimal quality version of the functionality to ensure the best possible service."
            }

        ]
    },
    {
        id: 3,
        color: "#2cb67d",
        backgroundColor: "rgba(44, 182, 125, .15)",
        title: "03. DEPLOYMENT",
        content: [
            {
                id: 1,
                icon: "git",
                title: "Code Versioning",
                description: "Each project is versioned in order to improve the monitoring of the code and the features produced. With agile development, each feature follows a cycle in order to be approved."
            },
            {
                id: 2,
                icon: "docker",
                title: "Containerization",
                description: "The containerization of our solutions makes it possible to put them into production more efficiently via cost-effective deployment mechanisms."
            },
            {
                id: 3,
                icon: "cicd",
                title: "Continuous Integration/Deployment",
                description: "These solutions allow us to ease the integration by building our containers but also to deploy them in a continuous and automatic manner via scripts and a structured architecture."
            }
        ]
    }
];

module.exports = methodology;