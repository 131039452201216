import React from "react";

export default function Tags({ title, content }) {
  return (
    <div className="tag">
      <h4 className="tag__title">{title}</h4>
      <ul>
        {content.map((hobby, index) => (
          <li className="tag__cards" key={index}>
            {" "}
            {hobby}{" "}
          </li>
        ))}
      </ul>
    </div>
  );
}
