import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

export default function Cursor({cursorRef, followRef}) {

  const cursorActualState = useSelector((state) => state.cursor.value)

  const _cursor = cursorRef;
  const _cursorf = followRef;

  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  useEffect(() => {
    const cursor = _cursor.current;
    const cursorf = _cursorf.current;

    document.addEventListener("mousemove", (event) => {
      const { clientX, clientY } = event;
      const mouseX = clientX;
      const mouseY = clientY;

      positionRef.current.mouseX = mouseX - cursorf.clientWidth / 2;
      positionRef.current.mouseY = mouseY - cursorf.clientHeight / 2;

      cursor.style.transform = `translate3d(${
        mouseX - cursor.clientWidth / 2
      }px, ${mouseY - cursor.clientHeight / 2}px, 0)`;

      /*cursor.style.top = `${mouseY}px`;
      cursor.style.left = `${mouseX}px`;*/

      /*cursorf.style.top = `${mouseY}px`;
      cursorf.style.left = `${mouseX}px`;*/
    });

    //const img = document.querySelector(".aboutme .container .image");
    //console.log(img);

    /*img.addEventListener("mouseenter", () => {
      console.log("it works");
      cursorf.style.transform = `scale(2)`;

    });*/

    /*
    // Elements animations
    img.addEventListener("mouseenter", function( event ) {
    });

    img.addEventListener("mouseleave", function( event ) {
    });
    */
  });

  useEffect(() => {
    const cursorf = _cursorf.current;

    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;
      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      cursorf.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
    };
    followMouse();
  }, []);

  return (
    <>
      <div className="app__cursor" ref={_cursor}>
        <img src={require(`../assets/${cursorActualState}.svg`).default} alt="eye" />
      </div>
      <div className="cursor__follower" ref={_cursorf}></div>
    </>
  );
}
