import React from "react";
import img from "../../assets/facebook.svg";

export default function MethodCard(props) {
  
const style = {
    backgroundColor: props.backgroundColor,
  };

  return (
    <>
      <div className="category__card">
        <div className="category__icon" style={style}>
          <img src={require(`../../assets/methodologies/${props.data.icon}.svg`).default} alt={props.data.icon}/>
        </div>
        <h3 className="category__card__title">{props.data.title}</h3>
        <p className="category__card__content">{props.data.description}</p>
      </div>
    </>
  );
}
