import gsap, { Power3 } from 'gsap';
import React, { useEffect, useRef } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { Link } from 'react-router-dom';
import avatar from '../assets/avatar.svg'
import SplitText from '../assets/utils/Split3.min.js'
import { useDispatch } from "react-redux";
import { changeCursor } from "../redux";

export default function Hero({ cursor, follow }) {

    const dispatch = useDispatch()

    const hoverAnimation = () => {
        cursor.current.classList.add("project");
        follow.current.classList.add("project");
        dispatch(changeCursor("link"))
    };

    const endAnimation = () => {
        cursor.current.classList.remove("project");
        follow.current.classList.remove("project");
        dispatch(changeCursor("link"))
    };

    const tl = useRef(null);


    useEffect(() => {

        const split = new SplitText(".hero__title", {
            type: "lines",
            linesClass: "lineChild"

        })
        const split2 = new SplitText(".hero__title", {
            type: "lines",
            linesClass: "lineParent"

        })

        tl.current = gsap.timeline()
            .from(split.lines, { top: 50, scale: 1, opacity: 0, duration: .4, ease: 'Power3.out', stagger: .3 }, 1)
            .from(".hero__text", { y: 50, duration: .6, opacity: 0, ease: 'Power3.out', stagger: .05, skewY: 2 })
            .from(".hero__image", { y: 100, duration: .5, opacity: 0, ease: 'out' }, 2)
            .from(".hero__cta", { x: -50, opacity: 0, duration: .6, ease: 'Power3.inOut' }, 1.4)
            .from(".hero__image img", { scale: 1.3, duration: .5, ease: 'Power3.inOut' }, 2)

    }, [])

    return (
        <>
            <div className="hero__content">
                <h1 className="hero__title">FULLSTACK</h1>
                <h1 className="hero__title">ENGINEER</h1>
                <p className="hero__text">I am a problem solver, my goal is to find a suitable and performant solution for each project by using the most efficient and scalable technologies available on the market.</p>
                <Link className="hero__cta" to="/project" onMouseEnter={hoverAnimation} onMouseLeave={endAnimation}>
                    <p className="cta__text">
                        show work
                    </p>
                    <FiArrowRight height="15" width="15" className="cta__icon" />
                </Link>
            </div>

            <div className="hero__image">
                <img loading="lazy" src={avatar} alt="avatar walim" />
            </div>
        </>
    )
}
