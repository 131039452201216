import {useEffect, useRef} from "react";
import EducationCard from "./EducationCard";
import education from "../assets/content/education";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Education() {

  let tl = useRef(null);

  useEffect(() => {
    
    tl.current = gsap.timeline()
    
    .from(".education__title",{x: 50, duration: 1.6,opacity:0, ease: 'power3.easeOut'})
    .from(".education__card",  {x: 100, opacity: 0, duration: 2, ease: "Power3.Easeout", stagger: .4 });


    ScrollTrigger.create({
      trigger: ".education",
      animation: tl.current,
      //markers: true, // reference
      start: "top center+=200px",
      end: "top center+=50px",
      scrub: 2.5,
      toggleActions:"play complete reverse reset",
      
    });


  })

  return (
    <div className="education">
      <div className="container">
        <h1 className="education__title">Education</h1>
        <div className="education__container">
          {education.map((edu, index) => (
            <EducationCard
              key={index}
              school={edu.school}
              logo={edu.logo}
              year={edu.year}
              degree={edu.degree}
              description={edu.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
