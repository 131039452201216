import React, { forwardRef, useRef, useEffect, useLayoutEffect } from "react";
import project from "../../assets/content/project";
import gsap from "gsap";
import SplitText from "../../assets/utils/Split3.min.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeCursor } from "../../redux";


const ProjectCard = forwardRef(
  ({ cursor, follow, data}, ref) => {
    const imageRef = useRef(null);
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);

    const dispatch = useDispatch()

    const hoverAnimation = () => {
      cursor.current.classList.add("project");
      follow.current.classList.add("project");
      // dispatch(changeCursor("link"))
      gsap.fromTo(
        imageRef.current,
        { scale: 1, ease: "power3.easeIn" },
        { scale: 1.3 }
      );
    };

    const endAnimation = () => {
      cursor.current.classList.remove("project");
      follow.current.classList.remove("project");
      // dispatch(changeCursor("link"))
      gsap.fromTo(
        imageRef.current,
        { scale: 1.3, ease: "power3.easeInOut" },
        { scale: 1 }
      );
    };

    let navigate = useNavigate();

    const setNavigation = () => {
      navigate(`${data.slug}`)
      endAnimation();
    }

    function wrap(el, wrapper) {
      console.log(el);
      el.parentNode.insertBefore(wrapper, el);
      wrapper.classList.add('line-wrapper')
      wrapper.appendChild(el);
  }

    let tl2 = useRef(null);

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    useEffect(() => {

      const split = new SplitText(".project__description", {
        type: "lines",
      });
  
      tl2.current = gsap
        .timeline()
        .from(
          imageRef.current,
          { scale: 1.2, duration: 0.7, ease: "power3.easeOut", stagger: 2.1}, 2.2
        )

        return () => {
          console.log("animation killed")
          // tl2.current.kill();
        };
    }, []);

    return (
      <>
        <div
          className="project__card"
          ref={ref}
          onMouseEnter={hoverAnimation}
          onMouseLeave={endAnimation}
          onClick={setNavigation}
        >
          <img loading="lazy" ref={imageRef} src={require(`../../assets/projects/${data.photo}.png`).default} alt={data.title} />
          <div className="overlay"></div>
          <div className="card__information">
            <h3 className="project__title">
              <span ref={titleRef}>{data.title}</span>
            </h3>
            <p className="project__description" ref={descriptionRef}>{data.tags}</p>
          </div>
        </div>
      </>
    );
  }
);

export default ProjectCard;
