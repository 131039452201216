import React, { useEffect } from "react";
import Method from "../components/Methodology/Method";
import methodology from "../assets/content/methodology";

export default function Methodology() {

  useEffect(() => {
    window.scrollTo(0, 0)
  })
  
  return (
    <>
      <section className="methodology">
        <div className="container">
          <h1>Methodology</h1>
          <p className="description">
          It's important for each system to have a procedure, each system works in a particular way, this section describes the way we conceptualize things but also the way we work from the beginning to the achievement in order to deliver the best quality result possible.
          </p>
          <div className="category__container">
            {methodology.map((data, index) => (
              <Method key={index} data={data} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
