import { React, useState, useEffect, useRef } from "react";
import gsap from "gsap";
import logo from "../assets/walimlogo.svg";
import social from "../assets/content/social";
import { Link } from "react-router-dom";

export default function Header() {
  const [toggled, setToggle] = useState(false);

  // Ref
  let btn_toggle = useRef(null);
  let bar0 = useRef(null);
  let bar1 = useRef(null);
  let bar2 = useRef(null);

  let menu = useRef(null);

  const q = gsap.utils.selector(menu);
  const tl = useRef();

  const home = useRef(null);
  const about = useRef(null);

  const Toggler = () => {
    setToggle(!toggled);
  };

  const menuClose = () => {
    setToggle(!toggled);
    tl.current.reverse(0)
  }

  useEffect(() => {
    tl.current = gsap.timeline();

    tl.current
      .from(".menu", { clipPath: 'inset(0 0 100% 0)', ease: "power3.out" }, .1)
      .from(q(".menu ul li"), {
        y: 100,
        opacity: 0,
        duration: 0.15,
        ease: "Power3.in",
        delay: 0.2,
        skewY: 5,
        stagger: 0.15,
      })
      .from(
        q(".menu__divider"),
        { width: "0%", opacity: 0, ease: "Power3.Easein" },
        0.5
      )
      .from(
        q(".menu__icons a"),
        {
          y: 10,
          opacity: 0,
          ease: "Power3.Easeout",
          stagger: 0.1,
        },
        0.05
      );
  }, []);

  useEffect(() => {
    const btn = btn_toggle.current;
    const bar_0 = bar0.current;
    const bar_1 = bar1.current;
    const bar_2 = bar2.current;
    const _menu = menu.current;

    if (toggled === true) {
      bar_0.style.backgroundColor = "rgba(36, 38, 41, 1)";
      btn.className = "button__toggled";
      bar_1.className = "bar1 bar1__active";
      bar_2.className = "bar1 bar2__active";
      _menu.className = "menu";
      tl.current.play();
    } else {
      btn.className = "";
      bar_1.className = "bar1";
      bar_2.className = "bar1";
      tl.current.reverse(0);
      bar_0.style.backgroundColor = "rgba(36, 38, 41, 0.6)";

      //_menu.className = "menu display_none";
    }
  }, [toggled]);

  return (
    <>
      <header className="container" ref={bar0}>
        <a className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </a>
        <button onClick={Toggler} ref={btn_toggle}>
          <div className="bar1" ref={bar1}></div>
          <div className="bar2" ref={bar2}></div>
        </button>
      </header>

      <div className="menu display_none" ref={menu}>
        <div className="menu__content">
          <ul>
            <li>
              <Link onClick={menuClose} to="/">HOME</Link>
            </li>
            <li>
              <Link onClick={menuClose} to="/methodology">METHODOLOGY</Link>
            </li>
            <li>
              <Link onClick={menuClose} to="/project">PROJECTS</Link>
            </li>
            <li>
              <Link className="disabled" onClick={menuClose} to="/project">ARTICLES</Link>
              <span>
                <p>SOON</p>
              </span>
            </li>
          </ul>

          <div className="menu__divider"></div>

          <div className="menu__icons">
            {
              social.map((network, index) => (
                <a target='_blank' key={index} href={network.link}>
                  <img src={require(`../assets/${network.name}.svg`).default} alt={network.name} className="social__icon" />
                </a>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}
