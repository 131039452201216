const social = [
    {
        id: 1,
        name: "youtube",
        link: "https://www.youtube.com/channel/UCwDco9lUtTmLbI0vz8DKhsg"

    },
    {
        id: 2,
        name: "github",
        link: "https://www.github.com/therealwalim"
    },
    {
        id: 3,
        name: "linkedin",
        link: "https://www.linkedin.com/in/therealwalim"
    },
    {
        id: 4,
        name: "dribbble",
        link: "https://dribbble.com/therealwalim"
    },
    {
        id: 5,
        name: "behance",
        link: "https://www.behance.net/therealwalim"
    },
  ];
  
  module.exports = social;