import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Education from "../components/Education";
import Experience from "../components/Experience";
import Skills from "../components/Skills";
import Contact from "../components/Contact";

export default function Landing({ cursorRef, followRef }) {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <div id="#hero" className="hero">
        <div className="container">
          <Hero cursor={cursorRef} follow={followRef} />
        </div>
      </div>
      <About />
      <Education />
      <Experience cursorRef={cursorRef} followRef={followRef} />
      <div className="skills">
        <div className="container">
          <h1 className="skills__heading">Skills</h1>
          <div className="skills__category">
            <Skills />
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
}
