import { Navigate, useParams } from 'react-router-dom';
import pj from '../../assets/content/project';
import Work from '../../pages/Work';

const findProject = slug => pj.find(project => project.slug == slug);

export const WorkWrapper = ({cursorRef, followRef}) => {
  const { slug } = useParams();

  const project = findProject(slug);

  return project != undefined || project != null ? <Work project={project} cursorRef={cursorRef} followRef={followRef} /> : <Navigate to="/project"/>;
};