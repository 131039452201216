const skills = [
    {
      id: 1,
      category: "Technical",
      icon: "technical",
      color: "rgba(127, 90, 240, .15)",
      textColor: "rgba(127, 90, 240, 1)",
      content: [
        {
          id: 1,
          title: "Full-stack Web Development",
          icon: "wd"
        },
        {
          id: 2,
          title: "Motion Design",
          icon: "md"
        },
        {
          id: 3,
          title: "Video Editing",
          icon: "ve"
        },
        {
          id: 4,
          title: "Graphic Design",
          icon: "gd"
        },
        {
          id: 5,
          title: "UI/UX Design",
          icon: "uixd"
        },
        {
          id: 6,
          title: "Social Media Strategy",
          icon: "sms"
        },
      ]
    },
    {
      id: 2,
      category: "Human",
      icon: "human",
      color: "rgba(44, 182, 125, .15)",
      textColor: "rgba(44, 182, 125, 1)",
      content: [
        {
          id: 1,
          title: "Creativity",
          icon: "ctv"
        },
        {
          id: 2,
          title: "Team Work",
          icon: "tw"
        },
        {
          id: 3,
          title: "Communication",
          icon: "cm"
        },
        {
          id: 4,
          title: "Sense of Initiative",
          icon: "soi"
        },
        {
          id: 5,
          title: "Autonomous",
          icon: "atn"
        },
        {
          id: 6,
          title: "Self-taught",
          icon: "st"
        },
      ]
    },
    {
        id: 3,
        category: "Technologies",
        icon: "tech",
        color: "rgba(255, 130, 0, .15)",
        textColor: "rgba(255, 130, 0, 1)",
        content: [
          {
            id: 1,
            title: "JavaScript",
            icon: "js"
          },
          {
            id: 2,
            title: "React",
            icon: "jsx"
          },
          {
            id: 4,
            title: "Adobe Creative Suite",
            icon: "acs"
          },
          {
            id: 5,
            title: "NodeJS",
            icon: "node"
          },
          {
            id: 6,
            title: "Git",
            icon: "git"
          },
          {
            id: 7,
            title: "Docker",
            icon: "docker"
          },
        ]
      },

  ];
  
  module.exports = skills;