import { configureStore, createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "link",
}

export const cursorSlice = createSlice({
  name: 'cursor',
  initialState,
  reducers: {
    changeCursor: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeCursor } = cursorSlice.actions

export const store = configureStore({
    reducer: {
      cursor: cursorSlice.reducer
    },
})