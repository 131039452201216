import React, { useState, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import pj from "../assets/content/project";
import SplitText from "../assets/utils/Split3.min.js";
import gsap from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import { useDispatch } from "react-redux";
import { changeCursor } from "../redux";
// import project from "../assets/content/project";

export default function Work(props) {

  gsap.registerPlugin(CSSRulePlugin)

  const dispatch = useDispatch()

    const hoverAnimation = () => {
      props.cursorRef.current.classList.add("project");
      props.followRef.current.classList.add("project");
      dispatch(changeCursor("link"))
    };

    const endAnimation = () => {
      props.cursorRef.current.classList.remove("project");
      props.followRef.current.classList.remove("project");
      dispatch(changeCursor("eye"))
    };

  const textRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !textRefs.current.includes(el)) {
      textRefs.current.push(el);
    }
  };

  const goToLink = (link) => {
    if(link) return window.open(link);
  }

  let tl = useRef(gsap.timeline({paused: true}));
  const imgRef = useRef();

  useEffect(() => {   
    window.scrollTo(0, 0);

    console.log(props.project.links.length === 0 ? "execute" : "remove")

    const split = new SplitText(textRefs.current, {
      type: "lines",
      linesClass: "lineChild"
    });
  
    const split2 = new SplitText(textRefs.current, {
      type: "lines",
      linesClass: "lineParent"
    });

    let imageReveal = CSSRulePlugin.getRule(".projectPage .container .illustration::after");

    if(typeof props.project != undefined || props.project != null){
      console.log("Tween execution")
      tl.current = gsap.timeline()
      .from(split.lines, {y: 100, stagger: .1, ease: "power3.easeIn", skewY: 5, autoAlpha: 0})
      .fromTo(imageReveal, { cssRule: { width: "100%" }, ease: "power3.easeIn", duration: .9},
      { cssRule: { width: "0%" }, ease: "power3.easeIn", duration: .9})
      .to(imgRef.current, { scale: 1, ease: "power3.easeIn", duration: .6},.7)
    }

    return () => {
      tl.current.kill();
      console.log("Component unmounted")
    };
  }, [])

  return (
    <div className="projectPage">
      <div className="container">
        <h1 ref={addToRefs}>{props.project.description}</h1>
        <div className="illustration">
            {props.project?.cover?.map((data) => (
              <img loading="lazy" key={data.id} className="preview__image" ref={imgRef} src={require(`../assets/projects/cover/${data.cover}.png`).default} alt={data.cover} />
            ))}
        </div>

        <div className="content__grid">
            <div className="paragraphs">
                {props.project?.content?.map(({title, text, image, id}) => (
                  <div key={id} className="paragraph__content">
                    <h3 className="content__title">{title}</h3>
                    <p className="content__text">
                    {text?.split("\n").map((item, index) => {
                      return index === 0 ? item : [<br key={index}/>, item];
                    })}
                    </p>
                    
                    {image ? <div className="content__image"><img loading="lazy" className="image__section" src={require(`../assets/${image}.jpg`).default} alt="" /></div> : console.log("No paragraph image")}
                </div>
                ))}
            </div>
            <div className="void"></div>
            <div className="side">
                {props.project.links.length === 0 ? null : <div className="links">
                  <h3 className="content__title">Explore</h3>
                  <div className="link__container">

                  {props.project.links?.map((data) => (
                    <div key={data.id} className="link__action" style={{backgroundColor: data.color}} onClick={() => goToLink(data.link)} onMouseEnter={hoverAnimation} onMouseLeave={endAnimation}>
                      <img loading="lazy" src={require(`../assets/projects/links/${data.icon}.svg`).default} alt="video"/>
                      <p>{data.text}</p>
                    </div>
                  ))}
                    
                  </div>
                </div>}
                <div className="technologies">
                  <h3 className="content__title">Technologies</h3>
                  <div className="container__technologies">
                    {props.project?.technologies?.map((data, index) => (
                      <div key={data.id} className="technologies__item">
                        <img loading="lazy" src={require(`../assets/projects/technologies/${data.icon}.svg`).default} alt="techno" />
                      </div>
                    ))}
                  </div>
                </div>
            </div>
        </div>

      </div>
    </div>
  );
}
