import React, { useRef } from "react";
import imgPosition from "../assets/position.svg";
import imgLocation from "../assets/location.svg";
import { useDispatch } from "react-redux";
import { changeCursor } from "../redux";

export default function ExperienceComponent({
  logo,
  company,
  year,
  position,
  location,
  tags,
  //refs
  exp_card,
  exp_title,
  exp_year,
  exp_img_con,
  exp_img,
  exp_loc,
  exp_pos,
  tag_title,
  tag,
  link,
  cursor,
  follow
}) {

  const dispatch = useDispatch()

  const redirect = ({ link }) => {
    if (!link) return;
    window.open(link, "_blank");
  }

  const hoverAnimation = () => {
    cursor.current.classList.add("project");
    follow.current.classList.add("project");
    dispatch(changeCursor("link"))
  };

  const endAnimation = () => {
    cursor.current.classList.remove("project");
    follow.current.classList.remove("project");
    dispatch(changeCursor("link"))
  };

  return (
    <>
      <div className="experience__card" ref={exp_card}>
        <div
          className="experience__heading"
          onClick={() => redirect({ link })}
          onMouseEnter={hoverAnimation}
          onMouseLeave={endAnimation}
        >
          <div className="experience__heading__image" ref={exp_img_con}>
            <img ref={exp_img} src={require(`../assets/${logo}.png`).default} alt="" />
          </div>
          <div className="heading__title">
            <div>
              <h1 ref={exp_title}>{company}</h1>
            </div>
            <h2 ref={exp_year}>{year}</h2>
          </div>
        </div>

        <div className="experience__info">
          <div ref={exp_pos} className="info__position">
            <img src={imgPosition} alt="position" />
            <p>{position}</p>
          </div>
          <div ref={exp_loc} className="info__location">
            <img src={imgLocation} alt="location" />
            <p>{location}</p>
          </div>
        </div>

        <div className="experience__tag">
          <h1 ref={tag_title}>Tags</h1>
          <ul>
            {tags.map((ex, index) => (
              <li ref={tag} className="tag__keywords" key={index}>
                {" "}
                {ex}{" "}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
