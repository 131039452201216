const project = [
  {
    id: 1,
    title: "Dirideal",
    tags: "Back-end Development • Front-end Development • DevOps",
    cover: [
      {
        id: 1,
        cover: "dirideal_preview",
      },
    ],
    description:
      "Dirideal is a marketplace for the sale of second-hand clothes.",
    photo: "dirideal",
    slug: "dirideal-marketplace",
    content: [
      {
        id: 1,
        title: "Scope",
        text: "Development and maintenance of DiriDeal, a marketplace dedicated to the sale of second hand clothes including social interaction and a gamified experience and also an admin panel to manage the operations.",
      },
      {
        id: 2,
        title: "My contribution",
        text: `I played a pivotal role in the development and maintenance of DiriDeal, a specialized marketplace dedicated to the resale of second-hand clothing, where I contributed significantly to the project's success. My responsibilities included executing the implementation of an efficient administration panel, overseeing content management, user and product handling, as well as settings and operational monitoring. Additionally, I took the lead in creating a versatile API that could cater to various user interfaces and seamlessly integrate with external services, such as a delivery service, enhancing the overall functionality of the platform. Furthermore, I successfully integrated HubSpot CRM to facilitate real-time transaction management, improving the user experience. As part of my contributions, I spearheaded the dockerization of the application and established robust CI/CD pipelines, streamlining and automating the deployment process directly to the cloud. These efforts not only enhanced the platform's performance but also contributed to a more interactive and gamified user experience on DiriDeal.`,
      },
      {
        id: 3,
        title: "Results",
        text: "In conclusion, my active involvement in the development and maintenance of DiriDeal has been marked by a diverse range of contributions that significantly enriched the platform. From spearheading the implementation of an efficient administration panel and creating a versatile API to integrating HubSpot CRM for real-time transaction management, my efforts aimed at enhancing user experience and streamlining operational processes. Additionally, leading the dockerization of the application and establishing robust CI/CD pipelines demonstrated a commitment to efficiency and innovation. As a result, DiriDeal stands as a testament to collaborative efforts and a dedication to creating a specialized marketplace for second-hand clothing that not only incorporates social interactivity and gamification but also boasts seamless functionality and automated deployment processes. My contributions have played a crucial role in shaping DiriDeal into a dynamic and user-friendly platform that meets the needs of both sellers and buyers in the realm of second-hand fashion.",
      },
    ],
    links: [
      {
        id: 1,
        text: "Check the App",
        link: "https://play.google.com/store/apps/details?id=com.dirideal&hl=en_US",
        icon: "video",
        color: "#7f5af0",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "react",
      },
      {
        id: 2,
        icon: "android",
      },
      {
        id: 3,
        icon: "nodejs",
      },
      {
        id: 4,
        icon: "mongodb",
      },
      {
        id: 5,
        icon: "docker",
      },
      {
        id: 6,
        icon: "firebase",
      },
      {
        id: 7,
        icon: "github",
      },
      {
        id: 8,
        icon: "cicd",
      },
    ],
  },
  {
    id: 1,
    title: "Watika",
    tags: "UI Design • Front-end Development • Back-end Development • GitOps",
    cover: [
      {
        id: 1,
        cover: "watika_preview",
      },
    ],
    description:
      "Watika is a product for pairing customers with courier partners.",
    photo: "watika",
    slug: "watika-service-provider",
    content: [
      {
        id: 1,
        title: "Scope",
        text: "Design of a complete solution to meet the administrative needs of users, including individuals and companies, by connecting them with couriers for a fee.",
      },
      {
        id: 2,
        title: "My contribution",
        text: `The first challenge was to analyze the needs of our client, once the specifications were in our custody, to establish a plan that would determine the scope of our project, to analyze potential risks that could affect our team during the completion of the project, to estimate an expected budget so that the client could know how much budget he would have to devote to the project, and finally the definition of metrics to assess the performance of the key elements of the project, i.e., the quality of the project, the deadlines, but also the cost of the project.\n
                An architecture has been set up to quickly build our project after an update that will take the form of a Docker container easily constructible via scripts that can be easily parsed by our Git client, which will be able to deploy our solution in future steps.\n
                The engine of our solution is our API (server), it needs a source where to get data to be displayed which is a database, in order to design it it is necessary to create a data schema which in our case will be relational but also in the form of objects regarding mobile data, these services will allow us to store our data.\n
                From our API we will be able to create what we call entities that will allow us to access our data more easily, repositories have been created to allow us to create personalized functions and to optimize the communication with the database, the controller will allow the API to execute various operations that are characterized in the communication of our software clients.\n
                Then follows our web platform made up of several categories facilitating the management and administration of the various components of our solution, it will allow the administrations to have a better vision of what is happening in our global environment.\n
                The mobile applications will serve both the client of the product and its couriers, they have been designed to establish a relationship between these two entities, one requesting and the other serving, they are made up of several customized functionalities that will aim to facilitate the control, to present statistical and financial data but most importantly to make this service valuable to the company, all this has been conceived in an optimized and dynamic way with regard to their software architecture.`,
      },
      {
        id: 3,
        title: "Results",
        text: "This experience allowed me to learn more about the development of large-scale solutions, especially by being more interested in the types of architectures such as modular architecture and other methods allowing me to optimize the workflow of my applications.",
      },
    ],
    links: [
      {
        id: 1,
        text: "Watch the video",
        link: "https://www.youtube.com/watch?v=9123PDVJp9U",
        icon: "video",
        color: "#7f5af0",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "react",
      },
      {
        id: 2,
        icon: "android",
      },
      {
        id: 3,
        icon: "symfony",
      },
      {
        id: 4,
        icon: "maps",
      },
      {
        id: 5,
        icon: "docker",
      },
      {
        id: 6,
        icon: "firebase",
      },
    ],
  },
  {
    id: 2,
    tags: "UI Design • Front-end Development • Back-end Development",
    title: "Yachat",
    cover: [
      {
        id: 1,
        cover: "yassir_preview",
      },
    ],
    description:
      "YAchat is a solution that allows users to contact YAssir's technical support.",
    photo: "yachat",
    slug: "yachat-customer-experience",
    content: [
      {
        id: 1,
        title: "Scope",
        text: `Realization of a platform allowing call center agents to receive user help requests (via the mobile application) directly on the web portal, but also to create the module that will allow the user to communicate with this platform in real-time.`,
      },
      {
        id: 2,
        title: "Challenge",
        text: `Learn how to use sockets to implement real-time communication channels while safeguarding their state in an environment using Google IAP to interconnect servers.`,
      },
      {
        id: 3,
        title: "My contribution",
        text: `In order to run an application, it needs a source from which to extract data to display which is a given database, in order to design it is necessary to create a schema that will identify as needed the objects we will need as well as the attributes that will allow us to establish links between our data.\n
            From this schema we will be able to create what we call "Schema" that will allow us to create our database architecture, in order to create it we will need a first sending of data, the controller will allow the API to perform various operations that we will need on our platform and the mobile application.\n
            Then will come our web platform made up of various components with instantaneous loading, it will allow the support agents to perform various operations to meet the needs of the user and thus control the call center.\n
            As for the mobile application, it will establish a direct link between users (driver or customers) and operators so that they can be taken care of as soon as possible and stay informed using the various links present and the robot assistant.`,
      },
      {
        id: 4,
        title: "Results",
        text: "This experience allowed me to learn more about a lot of new technologies that are in demand on the market while my main mission was to create a solution to put the customer in touch with technical support.",
      },
    ],
    links: [
      {
        id: 1,
        text: "Watch the video",
        link: "https://www.youtube.com/watch?v=OZIZmiqqoi4",
        icon: "video",
        color: "#7f5af0",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "react",
      },
      {
        id: 2,
        icon: "android",
      },
      {
        id: 3,
        icon: "nodejs",
      },
      {
        id: 4,
        icon: "javascript",
      },
      {
        id: 5,
        icon: "github",
      },
    ],
  },
  {
    id: 3,
    title: "Providata",
    tags: "UI Design • Front-end Development • Back-end Development",
    cover: [
      {
        id: 1,
        cover: "providata_preview",
      },
    ],
    description:
      "Providata is a solution allowing users to have access to their data in emergency situations.",
    photo: "providata",
    slug: "providata-utility-solution",
    content: [
      {
        id: 1,
        title: "Scope",
        text: `Implementation of a utility solution allowing users to keep certain information (contacts, messages, notes, passwords, etc...) accessible online in case of device loss or emergency, they will be able to have them available using a web application.`,
      },
      {
        id: 2,
        title: "Challenge",
        text: `Explore the different possibilities to retrieve system data from the mobile and synchronize them with a platform available on the Internet.`,
      },
      {
        id: 3,
        title: "My contribution",
        text: `In order to run an application, it needs a source from which to extract data to display which is a given database, in order to design it is necessary to create a schema that will identify as needed the objects we will need as well as the attributes that will allow us to establish links between our data.\n
            From this schema we will be able to create what we call "Schema" that will allow us to create our database architecture, in order to create it we will need a first sending of data, the controller will allow the API to perform various operations that we will need on our platform and the mobile application.\n
            Then will come our web platform made up of various components with instantaneous loading, it will allow the support agents to perform various operations to meet the needs of the user and thus control the call center.\n
            As for the mobile application, it will establish a direct link between users (driver or customers) and operators so that they can be taken care of as soon as possible and stay informed using the various links present and the robot assistant.`,
      },
      {
        id: 4,
        title: "Results",
        text: "This experience allowed me to learn more about a lot of new technologies that are in demand on the market while my main mission was to create a solution to put the customer in touch with technical support.",
      },
    ],
    links: [
      {
        id: 1,
        text: "Watch the video",
        link: "https://www.youtube.com/watch?v=vljVTgKvang",
        icon: "video",
        color: "#7f5af0",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "javascript",
      },
      {
        id: 2,
        icon: "react",
      },
      {
        id: 3,
        icon: "github",
      },
      {
        id: 4,
        icon: "laravel",
      },
      {
        id: 5,
        icon: "android",
      },
    ],
  },
  {
    id: 4,
    title: "Sustainable Cities",
    tags: "UI Design • Front-end Development • Branding • Marketing",
    cover: [
      {
        id: 1,
        cover: "sc_preview",
      },
    ],
    description:
      "It's a conference that aims to inform the audience about the state-of-the-art in environmental sustainability.",
    photo: "sustainable",
    slug: "sustainable-cities-conference",
    content: [
      {
        id: 1,
        title: "Scope",
        text: `The current issues - the focus of all concerns - revolve around two main themes: Smart City and Industry 4.0. In a worrying climate context where the levers of action are economic, environmental, social and even political, it is becoming essential to provide solutions for improving the quality, interactivity and performance of services in order to reduce costs and resource consumption.\n
      It is in this perspective that the "Smart City / Industry of the Future" project is embedded.\n
      The purpose of the conference is to carry out a study dealing with the field of sustainable development, as the main objective is the realization of an event for an external audience to promote the subject which will allow all of us to present the result of the work.`,
      },
    ],
    links: [
      {
        id: 1,
        text: "Watch the video",
        link: "https://www.youtube.com/watch?v=MwnX7enMJCU",
        icon: "video",
      },
      {
        id: 2,
        text: "Live website",
        link: "https://sustainablecities.vercel.app/",
        icon: "live",
      },
      {
        id: 3,
        text: "Visit the repo",
        link: "https://github.com/therealwalim/sustainablecities",
        icon: "github",
      },
      {
        id: 4,
        text: "Behance project",
        link: "https://www.behance.net/gallery/151491153/Sustainable-Cities-2022-Conference",
        icon: "design",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "javascript",
      },
      {
        id: 2,
        icon: "react",
      },
      {
        id: 3,
        icon: "github",
      },
      {
        id: 4,
        icon: "cicd",
      },
    ],
  },
  {
    id: 5,
    title: "Shirts",
    tags: "Front-end Development • Back-end Development",
    cover: [
      {
        id: 1,
        cover: "shirts_preview",
      },
    ],
    description: "Shirts is a solution to manage different points of sell.",
    photo: "shirts",
    slug: "shirts-pos-management",
    content: [
      {
        id: 1,
        title: "Scope",
        text: "Design and development of a commercial management platform allowing to have a global view on the commercial operations, on the stock and on the transactions carried out by the agents but also a global view on the partner sales points.",
      },
      {
        id: 2,
        title: "Challenge",
        text: `Implementation of various features such as authentication, role system, CRUD operations, log system, real-time changes.`,
      },
      {
        id: 3,
        title: "My contribution",
        text: `To run an application, it needs a source to extract data to display which is a database, in order to design it’s necessary to create a schema that will identify as needed the tables we will need and the relationships they maintain between them.\n
        From this schema we will be able to create what are called migrations which are designed to recreate our database automatically with one line of command and define the indexes.\n
        Then will come the creation of models that are classes allowing us to manipulate our tables as objects through Eloquent by creating relationships between them.\n
        Then comes our views, which represent the visual aspect of our application or the direct intermediary between the user and the server, so we have chosen to structure our pages into various sections to facilitate development including blade which will facilitate data integration, and Ajax which will improve the user experience.\n
        The use of routes is essential, they are the ones who create the paths for us to access views and controllers.\n
        And finally, we will talk about controllers, who are like the conductor of our application, he will supervise the display of our views, and coordinate the different operations used to facilitate the manipulation of features by the user.`,
      },
      {
        id: 4,
        title: "Results",
        text: "This experience allowed me to learn more about Laravel while my main mission was to create a web application with several functions that allow the sales management.",
      },
    ],
    links: [],
    technologies: [
      {
        id: 1,
        icon: "laravel",
      },
      {
        id: 2,
        icon: "javascript",
      },
      {
        id: 3,
        icon: "github",
      },
    ],
  },
  {
    id: 6,
    title: "CLOE",
    tags: "UI/UX Design",
    cover: [
      {
        id: 1,
        cover: "cloe_preview",
      },
    ],
    description:
      "Cloé's mission is to provide housing for students before they start school.",
    photo: "CLOE",
    slug: "cloe-student-flat-rental",
    content: [
      {
        id: 1,
        title: "Description",
        text: `CLOE is a project that aims to find accommodation for students before the start of the school year, by putting them in touch with apartment owners registered on the platform.\n
      CLOE manages the whole process from the registration of the actors, creation and completion of their administrative file, to the rental management.`,
      },
    ],
    links: [
      {
        id: 1,
        text: "Live website",
        link: "https://cloe-edu.fr/",
        icon: "live",
      },
      {
        id: 2,
        text: "Behance project",
        link: "https://www.behance.net/gallery/151388561/CLOE-Design-of-a-student-flat-rental-platform",
        icon: "design",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "adobexd",
      },
    ],
  },
  {
    id: 7,
    title: "Ambition Project",
    tags: "UI/UX Design",
    cover: [
      {
        id: 1,
        cover: "ma_preview",
      },
    ],
    description: "Ambition Project aims at boosting start-ups at their launch.",
    photo: "ma",
    slug: "ambition-project-boost-startups",
    content: [
      {
        id: 1,
        title: "Description",
        text: `Ambition Project aims to boost start-ups but also freelancers by highlighting them so that they can be solicited for various services.`,
      },
    ],
    links: [
      {
        id: 1,
        text: "Live website",
        link: "https://www.muslimambition.com/",
        icon: "live",
      },
      {
        id: 2,
        text: "Behance project",
        link: "https://www.behance.net/gallery/151226589/Ambition-Project-Design-of-a-freelancing-platform",
        icon: "design",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "adobexd",
      },
    ],
  },
  {
    id: 8,
    title: "TellUStory",
    tags: "UI/UX Design",
    cover: [
      {
        id: 1,
        cover: "tus_preview",
      },
    ],
    description:
      "TellUStory is an app that helps writers draw inspiration from shared notes.",
    photo: "tellustory",
    slug: "tellustory-notes-sharing",
    content: [
      {
        id: 1,
        title: "Description",
        text: `TellUStory is an application that aims to share literary notes, users will be able to share them with each other, the exchange of ideas will make our platform succeed in breaking through the free and creative minds, the idea is to establish a social system that will allow the user to have subscribers, to reach different levels but also to have an external opinion on his work.`,
      },
    ],
    links: [
      {
        id: 1,
        text: "Behance project",
        link: "https://www.behance.net/gallery/151124647/TellUStory-Mobile-app-for-sharing-literary",
        icon: "design",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "adobexd",
      },
    ],
  },
  {
    id: 9,
    title: "WeDent",
    tags: "UI/UX Design",
    cover: [
      {
        id: 1,
        cover: "wedent_preview",
      },
    ],
    description:
      "Wedent is a plateform to advertise dental services and providing medical advices.",
    photo: "wedent",
    slug: "wedent-dental-platform",
    content: [
      {
        id: 1,
        title: "Description",
        text: `Wedent is a platform for dental specialists to share their ads, ranging from recruiting assistants or partners to selling equipment, dentists will also have a section where they can write scientific articles.`,
      },
    ],
    links: [
      {
        id: 1,
        text: "Live website",
        link: "https://wedent.fr/",
        icon: "live",
      },
      {
        id: 2,
        text: "Behance project",
        link: "https://www.behance.net/gallery/150961027/Wedent-Design-of-a-platform-for-dental-consultations",
        icon: "design",
      },
    ],
    technologies: [
      {
        id: 1,
        icon: "adobexd",
      },
    ],
  },
];

module.exports = project;
