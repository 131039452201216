import React from "react";
import MethodCard from "./MethodCard";
import methodology from "../../assets/content/methodology";

export default function Method(props) {

  const style = {
    color: props.data.color
  }

  return (
    <>
      <div className="category__container__card">
        <h2 className="category__title" style={style}>{props.data.title}</h2>
        <div className="method__card__container">
        {props.data.content.map((content) => (
            <MethodCard
              key={content.id}
              data={content}
              backgroundColor={props.data.backgroundColor}
            />
          ))}
        </div>
      </div>
    </>
  );
}
