import React, {useEffect, useRef} from "react";
import phone from '../assets/phone.svg'
import email from '../assets/email.svg'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
import social from "../assets/content/social";

gsap.registerPlugin(ScrollTrigger);

export default function Contact() {

  let tl = useRef(null);

  useEffect(() => {
    tl.current = gsap.timeline()
    
    .from(".contact__title", {y: 50, duration: .6, opacity: 0, ease: 'Power3.in'})
    .from(".contact__p", {y: 50, opacity: 0, ease: 'Power3.in'})
    .from(".menu__icons .linked", {y: 30, autoAlpha: 0, ease: 'Power3.in', stagger: .2})
    .from(".btn__contact", {x: 50,duration: .6, opacity: 0, ease: 'Power3.in', stagger: .3})
  })

  return (
    <>
      <div className="contact" id="contact">
        <div className="container">
          <div className="contact__content">
            <div className="content__text">
              <h1 className="contact__title">Get in Touch</h1>
              <p className="contact__p">
              Let's discuss your project together and find the right solutions to make it happen.
              </p>
              <div className="menu__icons">
            {
              social.map((network, index) =>(
              <a target='_blank' key={index} href={network.link}>
              <img src={require(`../assets/${network.name}.svg`).default} alt={network.name} className="social__icon linked" />
            </a>
              ))
            }
          </div>
            </div>
            <div className="content__btn">
              <a className="btn__contact" href="mailto:contact@walim.dev">
                <img src={email} alt="email" />
                <p>contact@walim.dev</p>
              </a>

              <a className="btn__contact" href="tel:00213781879800">
                <img src={phone} alt="phone" />
                <p>+213 78 18 79 800</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
