import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import img from '../assets/wlmdessin.png'
import Tags from '../components/Tags';

gsap.registerPlugin(ScrollTrigger);

const hobbies = ['Soccer', 'Music', 'TV Shows', 'Video Games', 'FC Barcelona'];

export default function About() {
    const tl = useRef();

    useEffect(() => {
        
        tl.current = gsap.timeline()
        .from(".aboutme h1",{y: 50, duration: 1.2,opacity:0, ease: 'power3.easeOut'})
        .from(".aboutme p",{x: -100,duration:1.2,opacity:0, ease: 'power3.easeOut'})
        .from(".image",{y: 200,duration:1.6,opacity:0, ease: 'power3.easeOut'}, 1)
        .from(".image img",{scale: 1.6,duration: 1.6, ease: 'power3.easeOut'}, 1)
        .from(".tag__title", {y: 50,opacity:0, ease: 'power3.easeOut'})
        .from(".tag__cards",  {y: 20, opacity: 0, duration: .6, ease: "Power3.Easeout", stagger: 0.1 })

        ScrollTrigger.create({
          trigger: ".aboutme",
          animation: tl.current,
          //markers: true, // reference
          start: "top center+=200px",
          end: "top center+=0px",
          scrub: 3,
          toggleActions:"play complete reverse reset"
          
        });
    })
  return (
    <>
      <div id="about" className="aboutme">
        <div className="container">
          <div className="aboutme_text">
            <h1>About me</h1>
            <p>
            My name is Walim, born and raised in Algeria 🇩🇿, I've been passionately involved in the tech 💻 world since a very early age 👦🏻, I started writing my first lines of code at the age of 10, it was well after that I started to get interested in graphic design 🧑🏻‍🎨 and using the Adobe suite of design software, conceiving logos, content for social networks but also making video animations were my favoured passions.
            </p>
            <Tags title="Interests" content={hobbies}/>
          </div>
          <div className="image">
              <img loading="lazy" src={img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
