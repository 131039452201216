const education = [
  {
    id: "1",
    school: "CESI Graduate School of Engineering",
    logo: "cesilogo",
    year: "from 2017 to 2022",
    degree:
      "Manager in Information Systems",
    description:
      "CESI Graduate School of Engineering is a higher education school based on training around new technologies, it teaches its students the art of self-learning via the PBL (Problem Based Learning) method or learning by problem, this innovative method allows the student to be autonomous, to think by himself and to do research before consulting a tutor who will only be there to guide him as a last resort and this develops in him a certain motivation which will push him to look further and learn new technologies.",
  },
  {
    id: "2",
    school: "University of Silesia in Katowice",
    logo: "uslogo",
    year: "from 2021 to 2021",
    degree:
      "Erasmus+ Exchange (Computer Science)",
    description:
      "Courses taken within the institution:\n\nAdvanced object-oriented programming\nElements of software testing\nProgramming with the use of agile methodologies\nSecurity of Information Systems\nAlgorithmics and Advanced Data Structures\nModern Programming Languages",
  },
  {
    id: "2",
    school: "Bois des Cars High School",
    year: "from 2014 to 2017",
    degree:
      "High School Diploma in Experimental Sciences",
  },
];

module.exports = education;
