import React from "react";
import randomSchool from "../assets/university.png";

export default function EducationCard({
  school,
  logo,
  year,
  degree,
  description,
}) {


  return (
    <>
      <div className="education__card">
        <div className="education__heading">
          {logo ? (
            <div className="heading__image">
              <img src={require(`../assets/${logo}.png`).default} alt={logo} />
            </div>
          ) : (
            <div className="heading__image heading__image__blank">
              <img src={randomSchool} alt="school" />
            </div>
          )}
          <div className="heading__titles">
            <h1>{school}</h1>
            <h2>{year}</h2>
            <h3>{degree}</h3>
          </div>
        </div>

        {description ? (
          <div className="heading__description">
            <p>
              {description.split("\n").map((item, index) => {
                return index === 0 ? item : [<br key={index} />, item];
              })}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
}
