import React from "react";
import logo from "../assets/walimlogo-light.svg"
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="copyright">
            <h3>
              Design and code by <u><Link style={{color: 'white'}} to="/">@therealwalim</Link></u>
            </h3>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="walim logo" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
