import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import ProjectCard from "../components/Project/ProjectCard.js";
import project from "../assets/content/project.js";

export default function Project({cursorRef, followRef}) {
  let tl = useRef(null);

  const cardRefs = useRef([]);

  cardRefs.current = [];

  const addToRefs = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // window.scrollTo(0, 0)

    tl.current = gsap
      .timeline()
      .from(
        cardRefs.current,
        {
          clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",
          duration: 0.7,
          ease: "power3.easeIn",
          stagger: .3
        }
      );

      return () => {
        console.log("animation killed")
        // tl.current.kill();
      };
  }, []);

  return (
    <>
      <section className="project">
        <div className="container">
          <h1>Projects</h1>
          <p className="description">
          Here are the important projects on which I worked during the last years, this selection gathers several types of experiences with different particularities, including different issues and solutions to problems in varied environments, case studies will be available soon.
          </p>
          <div className="project__container">
            {project.map((data, index) => (
              <ProjectCard ref={addToRefs} key={index} data={data} cursor={cursorRef} follow={followRef} />
            ))}
            
          </div>
        </div>
      </section>
    </>
  );
}
